$primary: #cc071e;
$primary-dark: #a70619;
$secondary: #2D2416;
$white: #fff;
$hover-white: #F5F5F5;
$grey: #595959;
$primary-light: #FEF0F2;
$tip-background: #EBECF3;
$tip-text: #34638B;
$background-color: #f1f1f1;
$box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.11);
$box-shadow-hover: 0 0.3rem .6rem rgba(0, 0, 0, 0.21) !important;
$box-shadow-md: 0 0.2rem 1rem rgba(0, 0, 0, 0.11);
$modules__item__margin: 125px;

$discord: #5865F2;
$facebook: #176AE6;
$instagram: #F00075;
$youtube: #FF0000;
$whatsapp: #25D366;

$link-transition: all 200ms ease-in-out;
$svg-transition: fill 200ms ease-in-out;
$shadow-transition: all 300ms ease-in-out;
$button-transition: all 300ms ease-in-out;
$rotate-transition: all 500ms ease-in-out;
$border-width-transition: width 300ms ease-out;
