.some-footer {
  padding-top: 30px !important;

  .some-footer-wrapper {
    display: block;

    .some-footer-link {
      margin-right:20px ;
      margin-bottom: 10px;
      width: 30px;
      height: 30px;
      position: relative;
      display: inline-block;

      @include media-breakpoint-up(md) {
        margin-right:7px;
        width: 19px;
        height: 19px;
      }

      @include media-breakpoint-up(xl) {
        margin-right:10px;
        width: 22px;
        height: 22px;
      }

      svg {
        fill: #000;
        transition: $svg-transition;

        &.icon-instagram.st0  {
          &:hover {
            fill:$instagram !important;
          }
        }

        &.icon-facebook {
          &:hover {
            fill:$facebook;
          }
        }

        &.icon-whatsapp {
          &:hover {
            fill:$whatsapp;
          }
        }

        &.icon-discord {
          &:hover {
            fill:$discord;
          }
        }

        &.icon-youtube {
          &:hover {
            fill:$youtube;
          }
        }
      }

      .icon-instagram {
        &:hover {
          fill:$instagram !important;
        }
      }
    }
  }
}