.module__teaser-x-items {

  font-size: .95rem;

  .wrapper-card {
    .card {
      border: none;
      background: none;

      .card-inner {
        background: $white;
        height: 100%;

        .card-img-top {
          width: 100%;
          height: 250px;
          background-size: cover;
          background-position: top left;
          transition: all 1s ease-in-out;
        }

        a {
          &.w-100 {
            height: 250px;
            overflow: hidden;
            border-radius: 0.25rem !important;

            &:hover, &:focus {
              .card-img-top {
                transform: scale(1.1);
              }
            }
          }
        }
      }

        &.length {
          @include media-breakpoint-up(xl) {
            flex: 0 0 20%;
            max-width: 20%;

            .btn-arrow {
              padding-left: 2.5rem;
              font-size: 1.1rem;

              &::before {
                background-size: 21px 20px;
                width: 21px;
                height: 20px; 
                left: .75rem;
              }
            }
          }
      }

      .card-title {

        a {
          transition: $link-transition;
          color: $secondary;

          &:hover {
            text-decoration: none;
            color: $secondary;
          }
        }
      }

      .card-body {
       height:calc(100% - 250px);
        max-width: 100%;

        .card-title {
          font-size: 1.75rem;
       }
      }

      .btn {
        min-width: 100%;
        font-size: .9rem;
        letter-spacing: 0.0025rem;

        &.btn-primary {
          margin-bottom: 0;
          padding: .55rem .55rem .55rem 2rem;
          color: $secondary;
          border: 0px solid $white;

          &:hover {
            color: $primary;
          }
        }

        &:first-of-type {
          margin-top: 15px;
        }
      }
    }
  }
}
