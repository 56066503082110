section.module__item {
  //display: flex;
  //position: relative;
  &.section_margin {
    &_normal {
      margin-top: $modules__item__margin;
      @include media-breakpoint-down(xs) {
        margin-top: calc(#{$modules__item__margin} / 2);
      }
    }
    &_medium {
      margin-top: calc(#{$modules__item__margin} / 2);
      @include media-breakpoint-down(xs) {
        margin-top: calc(#{$modules__item__margin} / 3);
      }
    }
    &_minimal {
      margin-top: calc(#{$modules__item__margin} / 4);
      @include media-breakpoint-down(xs) {
        margin-top: calc(#{$modules__item__margin} / 6);
      }
    }
    &_none {
      margin-top: 0;
    }
  }
}