.module__text-block,
.module__text-block-teaser {

  .style_single_column_text {

    .element_content {
        column-count: 1;
    }
  }

  .style_two_columns_text {

    .element_content {

        @include media-breakpoint-up(md) {
          column-count: 2;
          column-gap: 30px;
        }

        @include media-breakpoint-up(lg) {
          column-gap: 60px;
        }
    }
  }

  .element_content {
    p {
      margin-bottom: 0;
    }

    p + p {
      margin-top: 1rem;

      &:last-of-type {
        margin-bottom: 1rem;
      }
    }
  }
}

.element_content {
  ul {
    li {
      margin-bottom: 10px;
    }
  }
}
