.module__checklist {

  .wrapper-module__checklist {
    background: $white;

    .subline {
      font-size: 1.75rem;
      font-weight: 500;
    }

    div {
      &.border-bottom {
        &:last-of-type {
          border: none !important
        }
      }

      position: relative;

      .wrapper-module__checklist-items {

        padding-left: 3.5rem;

        @include media-breakpoint-up(sm) {
          padding-left: 4rem;
        }

        .subline {
          margin-bottom: 0;
          font-size: 1.5rem;
        }

        &::before {
          content: '';
          background-image: url('../images/svg/checkmark.svg');
          background-size: 20px 25px;
          width: 20px;
          height: 25px;
          position: absolute;
          left: 1.5rem;
          top: 1.5rem;

          @include media-breakpoint-up(md) {
            background-size: 25px 30px;
            width: 25px;
            height: 30px;
            left: 1.75rem;
          }

          @include media-breakpoint-up(lg) {
            background-size: 28px 32px;
            width: 28px;
            height: 32px;
            left: 1rem;
          }
        }

        p {
          &:last-of-type {
            margin-bottom: 0;
          }
        }

        p + p {
          margin-top: .5rem;
        }
      }
    }
  }
}