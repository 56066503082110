section.module__jobs {

  h2 {
    color: $secondary;
  }

  .navtr {
    display: none;
  }

  #pageswap_bottom {
    overflow: hidden;
    color: $secondary !important;

    &.pageswap {
      a {
        &:first-of-type,
        &:last-of-type {
          display: block;
          margin: 15px auto 15px 5px;
        }
      }
    }
  }

  .stellensum {
    font-size: 1.75rem;
    line-height: 1.2;
    color: $secondary;
    font-weight: 600;
  }

  .stellenlst .stellen {
    display: flex;
    flex-wrap: wrap;

    div {
      width: 100%;
      margin-right: 0 !important;

      @include media-breakpoint-up(md) {
        width: 50%;
      }

      @include media-breakpoint-up(lg) {
        width: 33.33%;
      }

      &:hover {
        background-color: #E9E9E9 !important;
      }
    }
  }
}


.module__job_search {
 .job_item {
   .wrapper-job-item {
     color: $secondary;

      &:hover {
        text-decoration: none;
      }
   }
 }
}

