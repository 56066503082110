.rounded-15 {
  border-radius: 15px;
}

.rounded-32 {
  border-radius: 32px;
}

.rounded-md-32 {
  border-radius: 32px;
}

.rounded-md-15 {
  border-radius: 15px;
}