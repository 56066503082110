@font-face {
  font-family:'REWEMato-Web-Light';
  font-style: normal;
  font-weight: 200;
  font-display:swap;
  src: url(../fonts/REWEMato-Web-Light.woff) format('woff');
}

@font-face {
  font-family:'REWEMato-Web';
  font-style: normal;
  font-weight: 300;
  font-display:swap;
  src: url(../fonts/REWEMato-Web.woff) format('woff');
}

@font-face {
  font-family:'REWEMato-Web-Bold';
  font-style: normal;
  font-weight: 500;
  font-display:swap;
  src: url(../fonts/REWEMato-Web-Bold.woff) format('woff');
}

@font-face {
  font-family:'UnitRoundedOT';
  font-style: normal;
  font-weight: 400;
  font-display:swap;
  src: url(../fonts/UnitRoundedOT.woff) format('woff');
}

@font-face {
  font-family:'UnitRoundedOT-Bold';
  font-style: normal;
  font-weight: 600;
  font-display:swap;
  src: url(../fonts/UnitRoundedOT-Bold.woff) format('woff');
}

@font-face {
  font-family:'UnitRoundedOT-Black';
  font-style: normal;
  font-weight: 700;
  font-display:swap;
  src: url(../fonts/UnitRoundedOT-Black.woff) format('woff');
}

body {
  font-family: 'UnitRoundedOT', Helvetica, Arial, sans-serif;
}
ul, ol {
  padding-left: 1rem;
}
