h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6, .headline, .subline, .smallheadline {
  color: $secondary;
  line-height: 1.2;
  word-break: normal;
  font-family: 'REWEMato-Web', Helvetica, Arial, sans-serif;
  letter-spacing: 0 !important;
}

h1,.headline {
  font-size: 1.8rem;
  font-weight: 600;
  color: $primary;
  word-wrap: break-word;

  @include media-breakpoint-up(sm) {
    font-size: 2.2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
}

h2, .subline  {
  font-size: 1.4rem;
  word-break: break-word;

  @include media-breakpoint-up(sm) {
    font-size: 1.75rem;
  }
}

h3 {
  font-size: 1.4rem;
}

h4 {
  font-size: 1.25rem;
}

.smallheadline {
  margin-bottom: 0;
  color: $secondary;

  @include media-breakpoint-up(sm) {
    font-size: 1.4rem;
  }
}

.headline {
    margin-bottom: 0;
    color: $primary;
}

.subline {
    color: $secondary;
    margin-bottom: 0;
}

.headline + .subline {
  margin-top: 10px;
}