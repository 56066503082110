.btn {
  background-color: $primary;
  padding: 1rem;
  min-width: 220px;
  font-size: 1.1rem;
  text-align: left !important;
  line-height: 1.2;
  transition: $button-transition;
  z-index: 1000;

  &:active, &:active:focus, &:focus {
    box-shadow: none !important;
  }

  &.btn-arrow-simple {
    padding: 0 0 0 30px;
    background-color: transparent;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-weight: 600;
    border: 0px solid $white !important;

    &:before {
      content: '';
      background-image: url('../images/svg/arrows-red.svg');
      background-size: 22px 21px;
      width: 22px;
      height: 21px;
      position: absolute;
      left: 0;
    }

    &:active, &:active:focus, &:focus {
      box-shadow: none !important;
    }

    padding: 1rem 1rem 1rem 3rem;

    @include media-breakpoint-up(xl) {
      padding-left: 4rem;
    }

    &:hover {
      color: $primary !important;
      border: 0px solid $white !important;

      &:before {
        color: $primary !important;
      }
    }
  }

  &.btn-arrow, &.btn-arrow-up, &.btn-whatsapp, &.btn-youtube, &.btn-discord, &.btn-facebook, &.btn-instagram {
    position: relative;
    padding: 1rem 1rem 1rem 3rem;
    font-size: 0.95rem;

    &::before {
      content: '';
      background-image: url('../images/svg/arrows.svg');
      background-size: 22px 21px;
      width: 22px;
      height: 21px;
      position: absolute;
      left: 0.8rem;
      top: 14px;
      transition: ease-in-out all .75s;
    }

    @include media-breakpoint-up(xl) {
      &::before {
        content: '';
        background-image: url('../images/svg/arrows.svg');
        background-size: 24px 23px;
        width: 24px;
        height: 23px;
        position: absolute;
        left: 0.8rem;
        top: 14px;
      }
    }

    &:active, &:active:focus, &:focus {
      box-shadow: none !important;
    }

    @include media-breakpoint-up(xl) {
      padding-left: 3.5rem;
    }

    &.btn-instagram {
      background-color: $instagram;
      border-color: $instagram;

      &:hover {
        background-color:darken($instagram, 10%);
        border-color:darken($instagram, 10%);
      }

      &::before {
        background-image: url('../images/svg/instagram.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
    }

    &.btn-youtube {
      background-color: $youtube;
      border-color: $youtube;

      &:hover {
        background-color:darken($youtube, 10%);
        border-color:darken($youtube, 10%);
      }

      &::before {
        background-image: url('../images/svg/youtube.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
    }

    &.btn-discord {
      background-color: $discord;
      border-color: $discord;

      &:hover {
        background-color:darken($discord, 10%);
        border-color:darken($discord, 10%);
      }

      &::before {
        background-image: url('../images/svg/discord.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
    }

    &.btn-facebook {
      background-color: $facebook;
      border-color: $facebook;

      &:hover {
        background-color:darken($facebook, 10%);
        border-color:darken($facebook, 10%);
      }

      &::before {
        background-image: url('../images/svg/facebook.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
    }

    &.btn-whatsapp {
      background-color: $whatsapp;
      border-color: $whatsapp;

      &:hover {
        background-color:darken($whatsapp, 10%);
        border-color:darken($whatsapp, 10%);
      }

      &::before {
        background-image: url('../images/svg/whatsapp.svg');
        background-size: 24px 24px;
        width: 24px;
        height: 24px;
      }
    }

    &.btn-arrow {
      &:hover {
        &::before {
          transform: translateX(3px);
        }
      }
    }

    &.btn-arrow-up {
      min-width: 30px;
      font-size: 0;
      line-height: 0;
      width: 50px;
      height: 50px;
      border: 1px solid $primary;
      border-radius: 50px;
      padding: 0;

      &::before {
        left: 12px;
        top: 12px;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        transform: rotate(270deg);
      }

      @include media-breakpoint-up(md) {
        &:hover {
          &::before {
            transform: scale(1.1) !important;
          }
        }
      }
    }

    &.btn-teaser {
      position: absolute;
      font-size: 0;
      padding: 25px;
      height: 20px;
      width: 20px;
      min-width: 20px;
      right: 10px;
      bottom: -15px;
      border-radius: 40px;

      &::before {
        left: 14px;
        top: 14px;
      }
    }
  }
}

