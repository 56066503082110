.module__facts {

  .facts-wrapper {
    transition: all 2s ease-in-out;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &.item-highlight-true{
      background-color: $primary-light;

      @include media-breakpoint-up(md) {
        min-width: calc(66.66667% + 50px);
        max-width: calc(66.66667% + 50px);
      }
    }

    .facts-inner {
      position: relative;

      .facts-image {
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        width: 60px;
        height: 60px;

        @include media-breakpoint-up(lg) {
          width: 70px;
          height: 70px;
        }
      }

      .facts-content {
        .subline {
          font-family: 'UnitRoundedOT',Helvetica,sans-serif;
          margin-left: 70px;

          @include media-breakpoint-up(sm) {
            font-size: 1.5rem;
            margin-left: 0;
          }

          @include media-breakpoint-up(md) {
            font-size: 1.75rem;
          }
        }

        p {
          margin-bottom: 0;
        }

        p + p {
          margin-bottom: 1rem;
        }
      }
    }

    a {
      color: $primary;
      text-decoration: none;

      &:hover {
        color: $secondary;
        text-decoration: none;
      }
    }
  }
}
