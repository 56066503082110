.module__content-boxes {
  .container {

    .wrapper-module__content-boxes {
      background: $white;
      border-radius: 32px;

      .headline {
        color: $secondary;
        font-size: 1.75rem;

        @include media-breakpoint-up(sm) {
          font-size: 2rem;
        }
      }

      .subline {
        margin-bottom: 0;
        font-size: 1.5rem;
        color: $secondary;
      }

      p {
        color: $secondary;
        margin-top: 1rem;
      }

      p + p {
        margin-top: .5rem;
      }

      .module__content-boxes_image {
        border: 2px solid #cc071e;
        background-size: 60px;
        background-position: center;
        background-repeat: no-repeat;
        position: absolute;
        top: -50px;
        width: 100px;
        height: 100px;
        background-color: $white;
        border-radius: 50px;
        padding: 20px;
        left: calc(50% - 50px);
        max-width: 100%;

        &.module__content-boxes_image_link {
          @include media-breakpoint-up(sm) {
            transition: $shadow-transition ;

            &:hover {
              box-shadow: $box-shadow;
              transform: scale(1.04);
            }
          }
        }
      }

      .wrapper-module__content-boxes_content,
      .wrapper-module__content-boxes_content_image {

        ul {
          margin-top: 1rem !important;
          margin-bottom: 1rem !important;
        }
      }

      .wrapper-module__content-boxes_content_image {
        @include media-breakpoint-down(xl) {
          padding-top: 70px !important;
        }
      }
    }
  }
}
