.module__application-process {

  .application-process-wrapper {
      background-color: $white;
      position: relative;
      transition: all 2s ease-in-out;

    &:nth-of-type(2){
      top: -3px;

      @include media-breakpoint-up(md) {
        margin-top: 0;
      }
    }

    &:nth-of-type(3){
      top: -6px
    }

    &:nth-of-type(4){
      top: -9px
    }

    &:nth-of-type(5){
      top: -12px
    }

    &:nth-of-type(6){
      top: -15px
    }

    &:nth-of-type(7){
      top: -18px
    }

    &:last-of-type{
      margin-bottom: 0 !important;
    }

    &.border-dotted {
      border: 3px dotted $primary;
    }

    &.item-highlight-true{
      background-color: $primary-light;

      @include media-breakpoint-up(md) {
        min-width: calc(66.66667% + 50px);
        max-width: calc(66.66667% + 50px);
      }
    }

      .application-process-inner {
        position: relative;

          .application-process-image {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            position: absolute;
            top: -80px;
            left: calc(50% - 54px);
            width: 100px;
            height: 100px;
            background-color: $white;

            @include media-breakpoint-up(md) {
              left: -90px;
              top: 0;
            }

            &.border-application-process {
              border: 2px solid $primary;
              border-radius: 50%;
              background-size: 60px;
              background-position: center;
            }
          }

        .application-process-content {
          .subline, .headline {
              font-weight: 500;
          }
        }
      }

    a {
      color: $primary;
      text-decoration: none;
      transition: $button-transition;

      &:hover {
        color: $secondary;
        text-decoration: none;
      }
    }
  }
}
