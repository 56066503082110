.module__cta {
  .container {
    @include media-breakpoint-down(sm) {
      padding-top: 100px;
    }
    .wrapper-module__cta {
      background: $grey;
      border-radius: 32px;

    @include media-breakpoint-down(md) {
      padding-top: 120px;
    }
      .headline {
        color: $white;
      }
      .subline {
        margin-bottom: 0;
        font-size: 1.5rem;
        color: $white;
      }
      p {
        color: $white;
        margin-top: 1rem;
      }
      p + p {
        margin-top: .5rem;
      }
      .wrappper-module__cta_image {
        img {
          width: 100%;
          height: 100%;
        }
        @include media-breakpoint-down(sm) {
          position: absolute;
          top: -100px;
          width: 200px;
          height: 200px;
          background: $white;
          border-radius: 100px;
          border: 3px solid $grey;
          background-color: $grey;
          padding: 20px;
          left: calc(50% - 100px);
        }
      }
    }
  }
}



