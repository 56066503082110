footer {

  font-size: 1.2rem;

  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }

  .logo {
    svg {
        @include media-breakpoint-only(md) {
          position: relative;
          left: -25px;
      }
      }
    }

  .list {
    padding-left: 30px;

    @include media-breakpoint-up(lg) {
      width: 20% !important;
      max-width: 20% !important;
      padding: 0 5px;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      font-family: 'UnitRoundedOT-bold', Helvetica, Arial, sans-serif;;
      text-transform: uppercase;
      font-weight: bold;

      a {
        color: $secondary;
        transition: $link-transition;

        &:hover {
          color: $primary;
          text-decoration: none;
        }
      }

      ul {
        li {
          font-family: 'UnitRoundedOT', Helvetica, Arial, sans-serif;;
          text-transform: none;
          font-weight: normal;
          margin-bottom: 0;
          font-size: 0.8rem;
        }
      }
    }
  }

  .info {
    ul {
      margin-bottom: 0;
      padding-top: 15px;

      @include media-breakpoint-up(lg) {
        padding-top: 30px;
      }

      li {
        margin-bottom: 0;
        font-family: 'UnitRoundedOT', Helvetica, Arial, sans-serif;
        a {
          text-transform: none;
          font-weight: normal;
          margin-bottom: 0;
        }
      }
    }
  }
}
