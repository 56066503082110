header {
  position: -webkit-sticky !important;
  position: sticky;
  top: 0;
  z-index: 10000;
  background: $white;
  font-size: 1.3rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.1rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.3rem;
  }

  .container-fluid {
    height: 75px;
    background: none;
    border-color: $primary !important;

    .navbar-brand {
      height: calc(75px - 1rem);
      position: relative;
      z-index: 1;
      width: 130px;

      a {
        position: absolute;
        top: -10px;
        left: 0;

        @include media-breakpoint-up(sm) {
          top: -10px;
        }

        @include media-breakpoint-up(md) {
          top: -10px;
        }
      }
    }

    .navbar-toggler {
      background: none;
      border: none;

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    .navbar-expand-lg {

      .navbar-collapse {
        background: $white;
        padding-top: 4rem;
        -webkit-transition: all 100ms linear;
        -ms-transition: all 100ms linear;
        transition: all 100ms linear;
        position: absolute;
        left: 0;
        top: 74px;
        border-bottom: 2px solid #cc071e;

        @include media-breakpoint-up(lg) {
          background: none;
          top: 0;
          padding-top: 1rem;
          border-bottom: 0 solid #fff;
        }

      .navbar-nav {

        padding-bottom: 2rem;

        .nav-item {
          .nav-link {
            padding-left: 1.2rem;
            padding-right: 1.2rem;
            color: $primary;
            font-family: 'UnitRoundedOT';
            transition: $link-transition;

            &:hover {
              color: $secondary;
            }

            &.active {
              font-family: 'UnitRoundedOT-bold';
            }

            &:after {
              display:block;
              content:attr(name);
              font-family: 'UnitRoundedOT-bold';
              visibility: hidden;
              overflow: hidden;
              height: 0px;
            }
          }

          &:last-of-type {
            .nav-link {
              padding-right: 0;
            }
          }
        }
      }
      }
    }
  }
}

.header-image {
  min-height: 200px;
  background-size: cover;
  background-position: top right;
  position: relative;
  margin: 0 auto !important;

  &.header_image_size_small {

    min-height: 280px;

    @include media-breakpoint-up(md) {
      min-height: 350px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 400px;
      background-repeat: no-repeat;
      background-size: auto;
    }
  }

  &.header_image_size_large {

    min-height: 300px;

    @include media-breakpoint-up(md) {
      min-height: 450px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 550px;
      background-repeat: no-repeat;
      background-size: auto;
    }
  }

  &:before {
    content: '';
    background: rgb(51, 101, 139);
    background: linear-gradient(90deg, rgba(52, 99, 139, 0.85) 0%, rgba(52, 99, 139, 0.94) 10%, rgba(52, 99, 139, 0.93) 15%, rgba(52, 99, 139, 0.92) 20%, rgba(52, 99, 139, 0.85) 65%, rgba(52, 99, 139, 0) 100%);

    @include media-breakpoint-up(md) {
      background: linear-gradient(90deg, rgba(52, 99, 139, 0.8) 0%, rgba(52, 99, 139, 0.9) 35%, rgba(52, 99, 139, 0) 100%);
    }

    @include media-breakpoint-up(lg) {
      background: linear-gradient(90deg, rgb(52, 99, 139) 20%, rgba(52, 99, 139, 0.97) 25%, rgba(52, 99, 139, 0.96) 30%, rgba(52, 99, 139, 0.95) 40%, rgba(52, 99, 139, 0.9) 50%, rgba(52, 99, 139, 0.6) 60%, rgba(52, 99, 139, 0.5) 70%, rgba(52, 99, 139, 0.4) 75%, rgba(52, 99, 139, 0.25) 80%) ;
    }

    @include media-breakpoint-up(xl) {
      background: linear-gradient(90deg, rgb(52, 99, 139) 30%, rgba(52, 99, 139, 0.98) 35%, rgba(52, 99, 139, 0.97) 40%, rgba(52, 99, 139, 0.96) 43%, rgba(52, 99, 139, 0.95) 45%, rgba(52, 99, 139, 0.9) 50%, rgba(52, 99, 139, 0.6) 60%, rgba(52, 99, 139, 0.5) 70%, rgba(52, 99, 139, 0.4) 75%, rgba(52, 99, 139, 0.25) 80%) ;
    }

    position: absolute;
    left: 0;
    top: 0;
    min-height: 200px;
    height: 100%;
    width: 100%;
  }

  .headline, .subline {
    color: $white;

    @include media-breakpoint-up(md) {
      padding-left: 23px;
    }
  }

  .headline {
    font-size: 1.9rem;

    @include media-breakpoint-up(sm) {
      line-height: 1.25;
    }

    @include media-breakpoint-up(md) {
      font-size: 3rem;
      line-height: 1.25;
    }

    @include media-breakpoint-up(xl) {
      font-size: 3.5rem;
    }
  }

  .subline {
    font-size: 1.3rem;

    @include media-breakpoint-up(md) {
      font-size: 2rem;
      line-height: 1.25;
    }

    @include media-breakpoint-up(xl) {
      font-size: 2.5rem;
    }
  }
}
