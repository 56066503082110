#backtoptop {
  position: fixed;
  bottom: 20px;
  right: 27px;
  z-index: 99;
  opacity: 0;
  transition: $button-transition;

  @include media-breakpoint-up(md) {
    bottom: 70px;
  }

  @include media-breakpoint-up(xl) {
    bottom: 20px;
  }
}