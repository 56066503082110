html, body {
  //background-color: $background-color;
}

body {
  font-size: 17px;
  line-height: 1.75;
}

html {
  scroll-behavior: smooth;
}

.shadow {
  box-shadow: $box-shadow !important;

  @include media-breakpoint-down(md) {
    box-shadow: $box-shadow-md !important;
  }
}