.carousel {
  height: 50%;
}
.item,
.active,
.carousel-inner {
  height: 100%;
}

.carousel-item.active {
  display: flex;
}

.carousel-inner:before {
  content: '';
  border: 3px dotted $primary;
  border-radius: 32px;
  background-color: #fff;
  width: 100%;
  height: calc(100% - 50px);
  position: absolute;
  left: 0;
  top: 50px;

  max-height: 1000px;
  transition: max-height 0.25s ease-in;

  @include media-breakpoint-up(md) {
    height: 100%;
    top: 0;
  }
}

.fill {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  -o-background-size: cover;
}
.carousel-control.left {
  background-image: none;
  width: 54px;
  height: 54px;
  top: 50%;
  left: 20px;
  margin-top: -27px;
  line-height: 54px;
  border: 0 none;
  opacity: 2;
  text-shadow: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.carousel-control.right {
  background-image: none;
  width: 54px;
  height: 54px;
  top: 50%;
  right: 20px;
  margin-top: -27px;
  line-height: 54px;
  border: 0 none;
  opacity: 2;
  text-shadow: none;
  -webkit-transition: all 0.2s ease-in-out 0s;
  transition: all 0.2s ease-in-out 0s;
}
.carousel-indicators {
  right: 50%;
  top: auto;
  bottom: -60px;
  margin-right: -19px;
}
.carousel-indicators li {
  background: #c0c0c0;
}
.carousel-indicators .active {
  background: #1f1f21;
}
.carousel-indicators li {
  border: 0 none;
}
.carousel-control.right:hover,
.carousel-control.left:hover {
  opacity: 0.56;
  border: 1px solid #fff;
}
.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: auto;
  bottom: -60px;
  background-color: $primary;
  border: 2px solid $primary;
  border-radius: 40px;
  width: 40px;
  height: 40px;
  transition: $button-transition;
}

.carousel-control-prev {
    left: 30px;

  &.icon {
    position: relative;
    left: -3px;
  }
}

.carousel-control-next {
    left: 80px;

  &.icon {
    position: relative;
    left: 3px;
  }
}

.carousel-image {
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
}

.carousel-image-wrapper {

  text-align: center;

  @include media-breakpoint-down(sm) {
top: -20px
  }

  @include media-breakpoint-down(sm) {
    position: absolute;
    position: absolute;
    top: 0px;
    width: 100px;
    height: 100px;
    background: $white;
    border: 3px solid $primary;
    border-radius: 100px;
    padding: 20px !important;
    left: calc(50% - 60px);
  }
}

.carousel-content {

  .headline {
    color: $secondary;
  }

  @include media-breakpoint-down(sm) {
   padding-top: 60px!important;
  }
}

.module__carousel {
  .container {
    padding-bottom: 80px;
  }
}
