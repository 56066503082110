.module__teaser-cta-x-items  {
    .container {

      .module__teaser-cta-x-items__wrapper {
        background: $white;
        border-radius: 32px;

        @include media-breakpoint-down(sm) {

          &:last-of-type {
            .col-12 {
              margin-bottom: 0 !important;
            }
          }
        }

       @include media-breakpoint-up(xl) {
           flex: 0 0 20% !important;
           max-width: 20% !important;
         }

        p {
          color: $secondary;
          margin-top: 1rem;
          font-size: 0.95rem;
        }

        .module__teaser-cta-x-items__wrapper-link {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 1000;
        }

        .module__teaser-cta-x-items__image {
          margin: 0 auto;
          position: absolute;
          top: 20px;
          width: 75px;
          height: 75px;
          left: -25px;
          border-radius: 75px;
          padding: 12px;
          background-color: $white;
          border: 3px solid $primary;
          max-width: 100%;
          background-repeat: no-repeat;
          background-size: 60%;
          background-position: center;

          @include media-breakpoint-up(sm) {
            top: -50px;
            width: 100px;
            height: 100px;
            border-radius: 100px;
            padding: 20px;
            left: calc(50% - 50px);
            transition: $shadow-transition;
          }
        }

        .shadow-link {
          transition: $shadow-transition;

          &:hover {
            text-decoration: none;
            box-shadow: $box-shadow-hover;

            .module__teaser-cta-x-items__image {

              @include media-breakpoint-up(sm) {
                  box-shadow: $box-shadow;
                  transform: scale(1.03);
              }
            }
          }
        }

        .module__teaser-cta-x-items__wrapper-content {
          .subline {
            color: $secondary;
            font-size: 1.4rem !important;
          }
        }
      }
    }
  }
