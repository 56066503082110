p {
  margin-bottom: 0;
}

p + p {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@include media-breakpoint-up(md) {
  .text-md-center {
      text-align: center !important;
  }
}
section:last-of-type:not(.module__contact-person-single) {
  margin-bottom: $modules__item__margin;
}

::-moz-selection { background: $secondary; color: $white }
::selection {background: $secondary; color: $white }