.module__contact-person-list {

  .contact-person-list-wrapper {

    .card {
      border-left: 0;
      border-right: 0;
      border-radius: 0;

      &:not(:first-of-type) {
        border-bottom: 0 ;
      }

      .card-header {
        background-color: $white;
        border: none;
        position: relative;
      }

      .btn-link {
        border: none;
        background-color: $white;
        padding: 0;
        outline: none !important;
        text-decoration: none !important;

        &:hover, &:active {
          text-decoration: none !important;
        }

        span {
          content: '';
          background-image: url('../images/svg/plus.svg');
          background-size: 20px 20px;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 8px;
          left: -50px;

          @include media-breakpoint-up(sm) {
            background-size: 25px 25px;
            width: 25px;
            height: 25px;
            top: 5px;
            left: -60px;
            transition: $rotate-transition;
          }
        }

        h3 {
          font-size: 1.5rem;
          color: $secondary;
          transition: $link-transition;

          @include media-breakpoint-up(sm) {
            font-size: 1.75rem;
          }

          &:active {
            color: $primary
          }
        }

        &:hover {
          text-decoration: none;

          h3 {
            color: $primary;
          }

          @include media-breakpoint-up(sm) {
            span {
              transform: rotate(180deg);
            }
          }
        }

        &.collapsed {

          h3 {
            color: $primary;
          }
        }
      }

      &:first-of-type + .card {
        border-top: 0;
      }
    }

    .wrapper-contact, .wrapper-contact-link {
      font-family: 'UnitRoundedOT', Helvetica, Arial, sans-serif;

      .contact-image {
        width: 120px;
        min-width: 120px;
        height: 120px;
        background-size: cover;
        background-position: center center;
        margin-right: 25px;

        @include media-breakpoint-up(md) {
          width: 145px;
          height: 145px;
          min-width: 145px;
        }
      }

      .btn-arrow-simple {
        padding: .5rem .5rem .5rem 3rem;
        margin-bottom: 0px;

        &:active {
          border: none
        }
      }

      a {
        color: $secondary;
        transition: $link-transition;

        &:hover {
          color: $primary;
        }
      }
    }

    .wrapper-contact-link {
      font-family: 'UnitRoundedOT-Bold', Helvetica, Arial, sans-serif;
    }

    .wrapper-contact {
      .wrapper-contact-name,
      .contact-phone,
      .contact-mail {
        font-family: 'UnitRoundedOT', Helvetica, Arial, sans-serif;
        font-size: 1.2rem;
        position: relative;
        line-height: 1.5;
      }

      .contact-phone {

        @include media-breakpoint-down(md) {
          padding-left: 3rem;

          &::before {
            background-image: url('../images/svg/phone.svg');
            background-size: 29px 24px;
            width: 29px;
            height: 24px;
            top: 2px;
            position: absolute;
            left: 0;
            content: '';
          }
        }

        &.btn {
          background: $white;
          transition: $button-transition;
          border-color: #fff;

          @include media-breakpoint-down(md) {
            background-color: $primary;
            color: $white;
            font-size: .9rem;

            &::before {
              top: 18px;
              left: 10px;
            }

            a {
              color: $white;
            }

            &:hover {
              color: $white;
              background-color: #9b0517;
            }
          }

          line-height: 1.5;

          @include media-breakpoint-up(lg) {
            padding: 0;
          }

          &:hover {

          }
        }
      }

      .contact-mail {

        @include media-breakpoint-down(md) {
          padding-left: 3rem;

          &::before {
            background-image: url('../images/svg/mail.svg');
            background-size: 24px 16px;
            width: 24px;
            height: 16px;
            top: 6px;
            position: absolute;
            left: 0;
            content: '';
          }
        }

        a {
          color: $white;
          text-decoration: none;
          transition: $link-transition;

          &:hover {
            color: $secondary;
            text-decoration: none;
          }
        }

        &.btn {
          background: $white;
          border-color: #fff;

          @include media-breakpoint-down(md) {

            font-size: .9rem;

            &:hover {
              color: $white;
            }

            background: $primary;
            color: $white;

            &::before {
              top: 20px;
              left: 10px;
            }

            &:hover {
              color: $white;
              background-color: #9b0517;
            }
          }

          line-height: 1.5;

          @include media-breakpoint-up(lg) {
            padding: 0;
          }
        }
      }

      &:first-of-type {
        margin-top: 2rem !important;
      }
    }
  }

  .wrapper-contact + .wrapper-contact-link, .wrapper-contact-link + .wrapper-contact-link {
    margin-top: 1.5rem;
  }

  .contact-person-list-tip {

    .contact-person-list-tip-inner {
        background-color: $tip-background;
        max-width: 270px;

        h4 {
          color: $tip-text;
        }
    }
  }
}
