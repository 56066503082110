.iframe {
  iframe {
    height: 250px;
    border-radius: 32px;
    border-color: $white;

    @include media-breakpoint-up(sm) {
      height: 300px;
    }

    @include media-breakpoint-up(md) {
      height: 370px;
    }

    @include media-breakpoint-up(xl) {
      height: 600px;
    }
  }
}