.module__contact-person-single {

    margin-bottom: 0;

    .container-fluid {
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
      background-position: center center;
      background-size: cover;

    @include media-breakpoint-up(md) {
      min-height: 653px;
    }

    margin-bottom: 0 !important;

    @include media-breakpoint-down(sm) {
      background-color: $primary;
      background-image: none !important;
      }

      .container, .container .row {
        @include media-breakpoint-up(md) {
          min-height: 653px !important;
        }
      }

    .wrapper-content {
      background-color: $primary;
      color: $white;

      @include media-breakpoint-up(md) {
        box-shadow: $box-shadow;
      }

      .headline, .subline, h1, h2, h3, h4 {
        color: $white !important;
      }

      .headline {
        font-size: 2rem;
      }

      .subline {
        font-size: 1.5rem;
      }

      .wrapper-contact {
          .wrapper-contact-name,
          .contact-phone,
          .contact-mail {
            font-family: 'UnitRoundedOT-Bold', Helvetica, Arial, sans-serif;
            font-size: 1.2rem;
            position: relative;
            line-height: 1.5;
            padding-left: 3rem;

            &::before {
              position: absolute;
              left: 0;
              content: '';
            }
          }

        .wrapper-contact-name {
          &::before {
            background-image: url('../images/svg/person.svg');
            background-size: 25px 24px;
            width: 25px;
            height: 24px;

            @include media-breakpoint-up(lg) {
              background-size: 29px 28px;
              width: 29px;
              height: 28px;
            }
          }
        }

        .contact-phone {
          &::before {
            background-image: url('../images/svg/phone.svg');
            background-size: 29px 24px;
            width: 29px;
            height: 24px;
            top: 2px
          }

          &.btn {
            color: $white;
            @include media-breakpoint-down(md) {

              background: $primary-dark;
              font-size: .9rem;

              &::before {
                top: 18px;
                left: 10px;
              }

              a {
                color: $white;

                &:hover {
                  color: $white;
                }
              }
            }

            &::after {
              border: 1px solid $primary;
              transition: $border-width-transition;
              position: absolute;
              bottom: -3px;
              content: '';
              width: 0;
              left: 0;
            }

            &:hover {
              color: $hover-white;

              &::after {
                width: 100%;
                border: 1px solid $white;
              }
            }

            @include media-breakpoint-up(lg) {
              padding: 0 0 0 3rem;
            }
          }
        }

        .contact-mail {
          &::before {
            background-image: url('../images/svg/mail.svg');
            background-size: 24px 16px;
            width: 24px;
            height: 16px;
            top: 6px
          }

          a {
            color: $white;
            text-decoration: none;
            transition: $link-transition;

            &:hover {
              color: $hover-white;
              text-decoration: none;
            }
          }

          &.btn {
            color: $white;

            @include media-breakpoint-down(md) {

              background: $primary-dark;
              font-size: .9rem;

              &:hover {
                color: $white;
              }

              &::before {
                top: 20px;
                left: 10px;
              }
            }

            line-height: 1.5;

            &::after {
              border: 1px solid $primary;
              transition: $border-width-transition;
              position: absolute;
              bottom: -3px;
              content: '';
              width: 0;
              left: 0;
            }

            &:hover {
              color: $hover-white;

              &::after {
                width: 100%;
                border: 1px solid $white;
              }
            }

            @include media-breakpoint-up(lg) {
              padding: 0 0 0 3rem;
            }
          }
        }

        &:first-of-type {
          margin-top: 2rem !important;
        }
      }

      .wrapper-contact + .wrapper-contact {
        border-top: 2px solid #fff;
        padding-top: 1rem;
      }
    }
  }
}
